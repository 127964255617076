<template>
  <div class="online">
    <Steps :current="step" :link="link" :mode="mode"></Steps>
    <div class="container p-4 editCourse__container">
      <div class="row">
        <div class="col-12 text-start">
          <!-- <div class="mb-2">排定您的課程上線日期</div>
          <span class="d-inline-block mb-3" v-if="nowYear&&nowMonth&&nowDate&&nowHour&&nowMin">將會於{{ nowYear }}, {{ nowMonth }} {{ nowDate }}, {{ nowHour }}:{{ nowMin }}發佈</span>
          <span class="d-inline-block mb-3" v-else></span>
          <div class="row mb-3">
            <div class="col-6 mb-4">
              <span class="form-select-txt d-inline-block mb-2">月份</span>
              <select class="form-select py-3 position-relative" aria-label="Default select example" v-model="nowMonth">
                <option disabled value>請選擇月份</option>
                <option :value="m" v-for="m in Monthdata" :key="m">{{ m }}</option>
              </select>
            </div>
            <div class="col-3 mb-4">
              <span class="form-select-txt d-inline-block mb-2">日期</span>
              <select class="form-select py-3" aria-label="Default select example" v-model="nowDate">
                <option disabled value>請選擇日期</option>
                <option :value="d" v-for="d in date" :key="d">{{ d }}</option>
              </select>
            </div>
            <div class="col-3 mb-4">
               <span class="form-select-txt d-inline-block mb-2">年</span>
              <select class="form-select py-3" aria-label="Default select example" v-model="nowYear">
                <option disabled value>請選擇年份</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
              </select>
            </div>
            <div class="col-6">
              <span class="form-select-txt d-inline-block mb-2">時</span>
              <select class="form-select py-3" aria-label="Default select example"  v-model="nowHour">
                <option disabled value>請選擇時間</option>
                <option :value="h" v-for="h in hour" :key="h">{{ h }}</option>
              </select>
            </div>
            <div class="col-6">
              <span class="form-select-txt d-inline-block mb-2">分</span>
              <select class="form-select py-3" aria-label="Default select example"  v-model="nowMin">
                <option disabled value>請選擇時間</option>
                <option :value="m" v-for="m in min" :key="m">{{ m < 10 ? `0${m}` : `${m}` }}</option>
              </select>
            </div>

          </div> -->
          <div class="d-flex justify-content-start">
            <button class="btn btn-main mt-3 w-25 me-2" @click.prevent="toLastStep">上一步</button>
            <button class="btn btn-main mt-3 w-25" @click="save">發布</button>
          </div>
        </div>
      </div>
    </div>
    <Meta :title ="metaTitle" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Steps from '../components/Steps';

const Swal = require('sweetalert2');

export default {
  name: 'Edit4',
  data() {
    return {
      step: 4,
      link: '/dashboard/edit_course/online',
      Monthdata: [],
      nowMonth: '',
      nowDate: '',
      nowHour: '',
      nowMin: '',
      nowYear: '',
      date: 31,
      hour: 24,
      min: 60,
      ampm: 'AM',
      mode: 'launch',
      goNext: false,
      today: '',
      metaTitle: '編輯課程 - 元學堂',
    };
  },
  components: {
    Steps,
  },
  methods: {
    setPrice() {

    },
    save() {
      const vm = this;
      vm.goNext = true;
      this.$log.debug('complete');
      const course = JSON.parse(localStorage.getItem('Course'));
      // this.$log.debug(course);
      // this.$log.debug(this.userAuth);
      this.$http.post(`${this.$API_PATH}/Course`, course, {
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        vm.goNext = true;
        localStorage.removeItem('Course');
        localStorage.removeItem('syllabus');
        Swal.fire(
          '新增成功',
          `${res.data.message}`,
          'success',
        );
        setTimeout(() => {
          vm.$router.push('/dashboard/profile');
        }, 4000);
      }).catch((err) => {
        this.$log.debug(err.response);
        Swal.fire(
          '錯誤',
          '請稍候再試',
          'info',
        );
      });
    },
    toLastStep() {
      const vm = this;
      vm.goNext = true;
      vm.$router.push('/dashboard/edit_course/pricing');
    },
  },
  computed: {
    ...mapGetters(['userName', 'Avatar', 'userId', 'role', 'exp', 'userAuth', 'LoginProvider']),
  },
  watch: {
    nowMonth() {
      if (this.nowMonth == 'Apr' || this.nowMonth == 'Jun'
      || this.nowMonth == 'Sep' || this.nowMonth == 'Nov'
      ) {
        this.date = 30;
      } else if (this.nowMonth == 'Feb') {
        this.date = 28;
      } else {
        this.date = 31;
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    const vm = this;
    if (vm.goNext) {
      next();
    } else {
      Swal.fire({
        title: '',
        text: '提醒您，上架課程將不會暫存您的變更，需完成至第四步驟完成發布，本系統即會儲存並發布您的課程。',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: '取消',
        confirmButtonText: '離開',
      }).then((result) => {
        if (result.isConfirmed) {
          next();
        }
      });
    }
  },
  created() {
    this.Monthdata = ['Jan', 'Feb', 'Mar',
      'Apr', 'May', 'Jun',
      'July', 'Aug', 'Sep',
      'Oct', 'Nov', 'Dec'];
    this.today = new Date();
    this.nowMonth = this.today.getMonth();
    this.$store.dispatch('getUserAuthFromLocal');
  },
  mounted() {
    window.addEventListener('beforeunload', (e) => {
      const confirmationMessage = '你還沒有完成你的文章，就這樣離開了嗎？';

      (e || window.event).returnValue = confirmationMessage; // Gecko + IE
      return confirmationMessage; // Webkit, Safari, Chrome
    });
  },
  destroyed() {
    window.removeEventListener('beforeunload', (e) => {
      const confirmationMessage = '你還沒有完成你的文章，就這樣離開了嗎？';

      (e || window.event).returnValue = confirmationMessage; // Gecko + IE
      return confirmationMessage; // Webkit, Safari, Chrome
    });
  },
};
</script>
